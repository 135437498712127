import '@isceco/widget-library2/index.js'
import '@isceco/widget-library2/core.css'
import '@isceco/widget-library2/basic-elements/ScrollTop/ScrollTop.js'
import './views/Header/Header.js'
import './views/Navigation/Navigation.js'
import './views/Navigation/HorizontalNavigation.js'
import './views/MainView/MainView.js'
import './views/Footer/Footer.js'

window.iscecoWidgetLibrary.languageKey = 'rechner-frontend'
window.iscecoWidgetLibrary.supportedLanguages = ['de', 'fr', 'it']
