import {html} from '@isceco/widget-library2/external/lit';
import '@isceco/widget-library2/basic-elements/HorizontalNavigation/HorizontalNavigation.js'
import WebComponent from '../../WebComponent.js';
import Navigation from './Navigation.js';

export default class HorizontalNavigation extends WebComponent {

  get translationFile() {
    return './views/Navigation/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.navigationListener = _ => {
      this.reload()
    }
    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
    this.render()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    document.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
  }

  getTemplate() {
    const items = [
      {text: this.i18n.translate('navigation.landing'), url: 'Landing/2023', active: true},
      {text: this.i18n.translate('navigation.2024'), url: 'Landing/2024'}
    ]

    return html`
      <isceco-horizontal-navigation .items="${items}">
      </isceco-horizontal-navigation>
    `
  }
}
customElements.define('rechner-frontend-horizontal-navigation', HorizontalNavigation)
