import {html, render} from '@isceco/widget-library2/external/lit';
import I18n from './i18n.js';

export default class WebComponent extends HTMLElement {

  constructor() {
    super()
    this.i18n = new I18n()
    this.root = this

    this.reloadListener = _ => {
      this.reload()
    }
  }

  connectedCallback() {
    if (this.translationFile) {
      this.translationLoaded = this.i18n.loadConfiguration(this.translationFile)
        .then(i18n => {
          document.addEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.reloadListener)
          return i18n
        })
    }
  }

  disconnectedCallback() {
    document.removeEventListener(I18n.EVENT_KEYS.CHANGE_LANGUAGE, this.reloadListener)
  }

  reload() {
    this.render()
  }

  render() {
    if (this.translationFile) {
      this.translationLoaded.then(i18n => {
        this.renderTemplate()
      })
    } else {
      this.renderTemplate()
    }
  }

  renderTemplate() {
    if (this.css) {
      render(this.getTemplateWithCss(), this.root)
    } else {
      render(this.getTemplate(), this.root)
    }
  }

  getTemplateWithCss() {
    return html`
      <style>
        ${this.css}
      </style>
      ${this.getTemplate()}
    `
  }
}
