import {css, html} from '@isceco/widget-library2/external/lit';
import '@isceco/widget-library2/basic-elements/Footer/Footer.js'
import WebComponent from '../../WebComponent.js';

export default class Footer extends WebComponent {

  get translationFile() {
    return './views/Footer/i18n.json'
  }

  get css() {
    return css`
      ul {
        list-style-type: none;
        margin: 0;
      }

      a {
        text-decoration: none;
      }
    `
  }

  connectedCallback() {
    super.connectedCallback()
    this.reload()
    this.version = ''
  }

  reload() {
    fetch('./version.json').then(r => r.json()).then(ver => {
        this.version = `- (v${ver.version})`
        this.render()
      }).catch(_ => {
      this.render()
    })
  }

  getTemplate() {
    return html`
      <isceco-footer text="${this.i18n.translate('footer.text')} ${this.version}">
        <ul>
          <li>
            <a href="${this.i18n.translate('footer.rechtliches.link')}">${this.i18n.translate('footer.rechtliches')}</a>
          </li>
        </ul>
      </isceco-footer>
    `
  }
}

customElements.define('rechner-frontend-footer', Footer)
