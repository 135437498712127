import {html} from '@isceco/widget-library2/external/lit';
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '@isceco/widget-library2/basic-elements/Card/Card.js'
import '@isceco/widget-library2/basic-elements/Dropdown/Dropdown.js'
import '@isceco/widget-library2/basic-elements/NumberInput/NumberInput.js'
import WebComponent from '../../WebComponent.js';

export default class Landing extends WebComponent {

  get translationFile() {
    return './views/Landing/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.render()
    this.year = window.getNavigationId() !== undefined ? window.getNavigationId() : "2023"
    this.otherYear = this.year === "2023" ? 2024 : 2023
    this.price = null
    this.productType = 'regular'
    this.calcPrice = "0.00"
    this.maxIn = 1000000000000000000
    this.vatRates2023 = {
      regular: 0.077,
      reduced: 0.025,
    };

    this.vatRates2024 = {
      regular: 0.081,
      reduced: 0.026,
    };
  }

  getTemplate() {
    return html`
      <div class="container">
        <isceco-card text="${this.i18n.translate(`blickrichtung.${this.year}.detail`)}">
          <img src="./images/${this.year}.png" alt=${this.i18n.translate(`blickrichtung.${this.year}`)}
               style="width:100%; max-width: 500px"/>
        </isceco-card>

        <isceco-dropdown label="${this.i18n.translate('productType')}"
                         name="productType"
                         .items="${[
                           {value: 'reduced', name: this.i18n.translate('productType.reduced')},
                           {value: 'regular', name: this.i18n.translate('productType.regular')}
                         ]}"
                         required
                         value="${this.productType}"
                         @change="${e => this.updateProductType(e.detail.value)}"
        ></isceco-dropdown>
        ${this.renderReducedInfo()}

        <isceco-number-input
                           placeholder="${this.i18n.translate('price.placeholder', {'year': this.year})}"
                           label="${this.i18n.translate('price')} (CHF)" min="0"
                           help-text="${this.i18n.translate('price.hint')}"
                           max="${this.maxIn}"
                           @keyup="${e => this.updatePrice(e.detail.value)}"></isceco-number-input>

        <isceco-card text="${this.i18n.translate('result', {'year': this.otherYear})}">
          <span class="result">${this.calcPrice} CHF</span>
        </isceco-card>

        <isceco-card>
          ${this.i18n.translate(`formular.link.description`)}
          <isceco-link url="${this.i18n.translate('formular.link.url')}"
                       text="${this.i18n.translate(`formular.link.text`)}"
                       target="_blank"
          </isceco-link>

        </isceco-card>
      </div>
    `
  }

  updateProductType = value => {
    this.productType = value;
    this.calculatePrice();
  }
  updatePrice = value => {
    this.price = value;
    this.calculatePrice();
  }

  calculatePrice = _ => {
    let newPrice;

    if (this.year === "2023") {
      newPrice = this.price / (1 + this.vatRates2023[this.productType]) * (1 + this.vatRates2024[this.productType]);
    } else {
      newPrice = this.price / (1 + this.vatRates2024[this.productType]) * (1 + this.vatRates2023[this.productType]);
    }
    if (isNaN(newPrice) || this.price > this.maxIn) {
      this.calcPrice = "0.00";
    } else {
      this.calcPrice = newPrice.toFixed(2);
    }

    this.render();
  }
  renderReducedInfo = () => {
    if (this.productType === 'reduced') {
      return html`
        <isceco-card id="reducedInfo" hidden>
          <strong>${this.i18n.translate('reducedInfo.title')}</strong>
          <ul>
            <li>${this.i18n.translate('reducedInfo.nahrung')}</li>
            <li>${this.i18n.translate('reducedInfo.vieh')}</li>
            <li>${this.i18n.translate('reducedInfo.futtermittel')}</li>
            <li>${this.i18n.translate('reducedInfo.medikamente')}</li>
            <li>${this.i18n.translate('reducedInfo.zeitungen')}</li>
            <li>${this.i18n.translate('reducedInfo.pflanzen')}</li>
          </ul>
        </isceco-card>
      `
    } else {
      return html``
    }
  }
}
customElements.define('rechner-frontend-landing', Landing)
