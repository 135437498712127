import {html} from '@isceco/widget-library2/external/lit';
import WebComponent from '../../WebComponent.js';
import Navigation from '../Navigation/Navigation.js';
import Landing from '../Landing/Landing.js';

export default class MainView extends WebComponent {

  constructor() {
    super();
  }

  connectedCallback() {
    super.connectedCallback();
    this.oldChild = null;
    this.navigationListener = e => {
      this.loadView(e.detail);
    }
    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener);

    this.loadView(getNavigationHash());
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener);
  }

  loadView(linkName) {
    if (isEmpty(linkName)) {
      this.redirectToDefault();
    } else {
      const newChild = this.returnNewChild(linkName);
      if (this.oldChild) {
        this.root.replaceChild(newChild, this.oldChild);
      } else {
        this.root.appendChild(newChild);
      }

      this.oldChild = newChild;
    }
  }

  returnNewChild(linkName) { //NOSONAR
    //TODO: 2023 / 2024
    return new Landing();
  }

  redirectToDefault() {
    navigate({to: 'Landing', id:'2023'});
  }

  getTemplate() {
    return html`rechner-frontend-main-view`;
  }
}

customElements.define('rechner-frontend-main-view', MainView)
